<!--
 * @Author: FengHang
 * @LastEditors: FengHang
 * @Date: 2021-06-04 18:10:10
 * @LastEditTime: 2021-06-10 17:09:19
-->
<template>
	<div class="noticeDetail">
		<div class="noticeDetail-inner">
			<div class="noticeDetail-title">
				{{noticeDetail.title}}
			</div>
			<div class="noticeDetail-time">
				{{noticeDetail.createTime}}
			</div>
			<div class="noticeDetail-content" v-html="noticeDetail.content">
			</div>
		</div>
	</div>
</template>
<script>
	import {
		noticeDetailMb
	} from "@/api/mobile/notice";
	export default {
		data() {
			return {
				noticeDetail: {
					// title: "公告标题",
					// time: "2022-06-21 10:21",
					// content: "我们旨在基于信任，通过技术和服务，发展我们的企业合作伙伴成长，推动他们的业务增长。我们追求成为100万家企业的首选合作伙伴，建立基于信任的合作。"
				},
				noticeId: "",
			}
		},
		mounted() {
			this.noticeId = this.$route.query.noticeId
			this.getNoticeDetail()
		},
		methods: {
			// 公告详情
			getNoticeDetail() {
				noticeDetailMb(this.noticeId, {
					id: this.noticeId
				}).then((res) => {
					if (res.code == 0) {
						this.noticeDetail = res.data
					}
				})
			},
		},
	}
</script>
<style lang="scss" scoped>
	/deep/p {
		margin: 0 0;
		padding: 0 0;
	}

	.noticeDetail {

		.noticeDetail-inner {
			display: flex;
			flex-direction: column;
			background-color: #ffffff;
			padding: 0.28rem 0.28rem;
			box-sizing: border-box;

			.noticeDetail-title {
				font-size: 0.32rem;
				font-family: PingFang SC;
				font-weight: bold;
				line-height: 0.44rem;
				color: #303133;
			}

			.noticeDetail-time {
				font-size: 0.24rem;
				font-family: PingFang SC;
				font-weight: 400;
				line-height: 0.34rem;
				color: #B4BCC6;
				margin: 0.16rem 0 0.28rem 0;
			}

			.noticeDetail-content {
				font-size: 0.28rem;
				font-family: PingFang SC;
				font-weight: 400;
				line-height: 0.4rem;
				color: #606266;
				word-break: break-all;
			}
		}
	}
</style>
