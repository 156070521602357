import axios from '@/utils/request'
let Api = {
	noticeList: "/announcementManage/list",
	noticeDetail: "/announcementManage/",
	noReadNum:"/announcementManage/noReadNum"
}
// 公告列表
export function noticeListMb(params) {
	return axios({
		method: 'get',
		url: Api.noticeList,
		params: params
	})
}
// 公告详情
export function noticeDetailMb(id, params) {
	return axios({
		method: 'get',
		url: Api.noticeDetail + id,
		params: params
	})
}
//未读公告数量
export function noReadNum(params) {
	return axios({
		method: 'post',
		url: Api.noReadNum,
		params: params
	})
}